import React from "react";
import "./accueil.css";
import { Col, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Zoom from "react-reveal/Zoom";
import { Link } from "react-router-dom";

function Accueil() {
  return (
    <section className="accueil-content">
      <Row className="ligne">
        <Col className="column">
          <Zoom left cascade>
            <a href="http://www.ruffa-karting.fr/magasin/">
              <Card style={{ width: "18rem" }}>
                <div className="overlay">
                  <h3>Magasin</h3>
                </div>
                <Card.Img variant="top" src="/imgReact/magasin.jpg" />
              </Card>
            </a>

            <Link to="/kart-occasion">
              <Card style={{ width: "18rem" }}>
                <div className="overlay">
                  <h3>Occasions</h3>
                </div>
                <Card.Img variant="top" src="/imgReact/occas.jpg" />
              </Card>
            </Link>
            <Link to="/location">
              <Card style={{ width: "18rem" }}>
                <div className="overlay">
                  <h3>Location 2T</h3>
                </div>
                <Card.Img variant="top" src="/imgReact/location.png" />
              </Card>
            </Link>
            <a
              href="https://www.fun-karting.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Card style={{ width: "18rem" }}>
                <div className="overlay">
                  <h3>Location 4T</h3>
                </div>
                <Card.Img variant="top" src="/imgReact/logo_fka.png" />
              </Card>
            </a>
            <a
              href="https://licence.ffsa.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Card style={{ width: "18rem" }}>
                <div className="overlay">
                  <h3>Licence</h3>
                </div>
                <Card.Img variant="top" src="/imgReact/ffsa.jpg" />
              </Card>
            </a>
          </Zoom>
        </Col>
        <Col className="background">
          <img className="logo" src="/imgReact/logo.png" alt="logo" />
        </Col>
      </Row>
    </section>
  );
}

export default Accueil;
