import React, { useState } from "react";
import "./location.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Carousel, Col, Row, Table, Button } from "react-bootstrap";
import { Line } from "rc-progress";
import { Link, useHistory } from "react-router-dom";

function Location() {
  const [adminToken, setAdminToken] = useState("");
  const [messageVerification, setMessageVerification] = useState("");
  const history = useHistory();

  function getVerification() {
    const token = localStorage.getItem("token");
    if (token) {
      setAdminToken(token);
      history.push("/reservation");
    } else {
      localStorage.removeItem("token");
      setMessageVerification(
        "Vous n'êtes pas connecté, veuillez vous connecter ou créer un compte"
      );
    }
  }
  return (
    <section>
      <Header />
      <div>
        <Carousel className="content">
          <Carousel.Item>
            <img className="d-block w-100" src="/imgReact/4.jpg" alt=" " />
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src="/imgReact/2.jpg" alt=" " />
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src="/imgReact/1.jpg" alt=" " />
          </Carousel.Item>
        </Carousel>
        <div className="location-container">
          <h1 className="title">Location kart compétition</h1>
          <Row className="row-ligne">
            <Col>
              <Row>
                <div className="column-left">
                  <p>Moteur Rotax Max 2 temps</p>
                  <Line percent="85" strokeWidth="1" strokeColor="#ff0000" />
                  <p>Cylindré : 125 cc</p>
                  <Line percent="80" strokeWidth="1" strokeColor="#ff0000" />
                  <p>Puissance : 30 Cv</p>
                  <Line percent="70" strokeWidth="1" strokeColor="#ff0000" />
                  <p>Vitesse Max : 130 km/h</p>
                  <Line percent="90" strokeWidth="1" strokeColor="#ff0000" />
                  <p>Difficulté</p>
                  <Line percent="50" strokeWidth="1" strokeColor="#ff0000" />
                </div>
              </Row>
            </Col>
            <Col>
              <div className="column-right">
                <h3>Piste Fun Kart 1180 m</h3>
                <img src="/imgReact/pistefun-kart.jpg" alt=" " />
              </div>
            </Col>
          </Row>
          <hr />
          <div className="conditions-container">
            <div className="conditions">
              <h4>Conditions</h4>
              <ul>
                <li>Avoir 13 ans ou plus</li>
                <li>Présenter votre pièce d'identité</li>
                <li>Chèque de caution de 1000 euros*</li>
                <li>Licence journalière délivrée par la FFSA</li>
              </ul>
              <p>
                * Chèque de caution de 1000 euros demandé ( par personne ),
                rendu à la fin de la location. En cas de détérioration du kart
                due à un accrochage ou sortie de piste les pièces seront
                facturées ainsi que la main d'oeuvre.
              </p>
            </div>
            <div className="licence">
              <h4>Licence</h4>
              <p>
                Pour obtenir votre licence merci de suivre les étapes au lien
                suivant
              </p>
              <div className="licence-link">
                <Link to="/licence">Obtenir ma licence</Link>
              </div>
              <p className="diff-center">
                En cas de difficulté n'hésitez pas à me contacter.
              </p>
            </div>
          </div>
          <hr />
          <div className="table-price">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Forfait</th>
                  <th>Prix</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>15 minutes</td>
                  <td>95 euros</td>
                </tr>
                <tr>
                  <td>30 minutes</td>
                  <td>190 euros</td>
                </tr>
              </tbody>
            </Table>
          </div>
          <div className="bottom-lign">
            <p>
              Equipement complet fourni (casque, combinaison, minerve et une
              paire de gants, pare-côte)
            </p>
          </div>
          <div className="button-reservation">
            <div className="response-valide">{messageVerification}</div>
            <Link>
              <Button onClick={getVerification}>Réservation</Button>
            </Link>
            <div className="center-black">
              <p className="black">
                * Réservation possible également par téléphone
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
}

export default Location;
