import React, { useState } from "react";
import "./header.css";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { HiOutlineHome } from "react-icons/hi";
import { GiHamburgerMenu } from "react-icons/gi";
import Nav from "react-bootstrap/Nav";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Account from "../../Components/Location/Account/Account";
import { MdAccountCircle } from "react-icons/md";

function Header() {
  const [displayNav, setDisplayNav] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);

  const [showLogin, setShowLogin] = useState(false);
  const handleCloseLogin = () => setShowLogin(false);
  const handleShowLogin = () => setShowLogin(true);

  function connect() {
    let nom = localStorage.getItem("token");
    if (nom) {
      return (
        <Link className="nav-link" to="/moncompte">
          <MdAccountCircle className="myAccount" /> Mon compte
        </Link>
      );
    } else {
      return (
        <Link className="nav-link" onClick={handleShowLogin}>
          Se connecter
        </Link>
      );
    }
  }

  function showMenuMobile() {
    if (displayNav) {
      return (
        <div className="menu-mobile-global">
          <Nav
            onClick={() => {
              setDisplayNav(false);
            }}
          >
            <ul className="nav-menu">
              <li>
                <Link to="/">
                  <HiOutlineHome />
                  Accueil
                </Link>
              </li>
              <li>
                <a href="http://www.ruffa-karting.fr/magasin/">Magasin</a>
              </li>
              <li>
                <Link to="/location">Location kart compétition</Link>
              </li>
              <li>
                <Link to="/kart-occasion">Kart occasion</Link>
              </li>
              <li>{connect()}</li>
              <li>
                <HashLink
                  onClick={() => {
                    setShowModal(!showModal);
                  }}
                >
                  Contact
                </HashLink>
              </li>
            </ul>
          </Nav>
        </div>
      );
    }
  }

  return (
    <header>
      <GiHamburgerMenu
        className="menu-mobile"
        onClick={() => {
          setDisplayNav(!displayNav);
        }}
      />
      <div className="header">
        <img id="logo" src="/imgReact/logo.png" alt="logo" />
        <div className="container">
          <nav id="nav-menu-container">
            <ul className="nav-menu">
              <li>
                <Link to="/">
                  <HiOutlineHome />
                  Accueil
                </Link>
              </li>
              <li>
                <a href="http://www.ruffa-karting.fr/magasin/">Magasin</a>
              </li>
              <li>
                <Link to="/location">Location kart compétition</Link>
              </li>
              <li>
                <Link to="/kart-occasion">Kart occasion</Link>
              </li>
              <li>{connect()}</li>
              <li>
                <HashLink
                  onClick={() => {
                    setShowModal(!showModal);
                  }}
                >
                  Contact
                </HashLink>
              </li>
            </ul>
          </nav>
        </div>{" "}
      </div>
      {showMenuMobile()}
      <div>
        <Modal show={showModal} onHide={handleClose}>
          <Modal.Header>
            <Modal.Title id="modal-title">Contact</Modal.Title>
          </Modal.Header>
          
          <Modal.Footer>
            <p>Vous pouvez me contacter:</p>
            <p>Mail: location@ruffa-karting.fr</p>
            <p>Tel: 06.07.23.70.22</p>
            <Button id="button-close" variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal id="login-modal" show={showLogin} onHide={handleCloseLogin}>
          <Modal.Body id="body-modal">
            <Account handleCloseLogin={handleCloseLogin} />
          </Modal.Body>
        </Modal>
      </div>
    </header>
  );
}

export default Header;
