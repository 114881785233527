import React, { useEffect, useState } from "react";
import "./reservation.css";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import { Button, Form } from "react-bootstrap";
import { RiErrorWarningLine } from "react-icons/ri";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import subDays from "date-fns/subDays";
import { registerLocale } from "react-datepicker";
import { useHistory } from "react-router-dom";
import fr from "date-fns/locale/fr";
registerLocale("fr", fr);

function Reservation() {
  const [checkbox, setCheckbox] = useState({});
  const [heure, setHeure] = useState({});
  const [forfait, setForfait] = useState({});
  const [person, setPerson] = useState({});
  const [client, setClient] = useState({});
  const [responseMail, setResponseMail] = useState("");
  const [requireNotification, setRequireNotification] = useState("");
  const history = useHistory();

  const handleCheckbox = (e) => {
    setCheckbox({ ...checkbox, [e.target.name]: e.target.value });
  };
  const handleHeure = (e) => {
    setHeure({ ...heure, [e.target.name]: e.target.value });
  };
  const handleForfait = (e) => {
    setForfait({ ...forfait, [e.target.name]: e.target.value });
  };

  const handlePerson = (e) => {
    setPerson({ ...person, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    getClient();
  }, []);

  const [startDate, setStartDate] = useState(new Date());
  const isWeekday = (date) => {
    const day = date.getDay();
    return day !== 1 && day !== 2 && day !== 3;
  };

  const globalDate = new Date(startDate);
  const month = globalDate.getMonth();
  const year = globalDate.getFullYear();
  const day = globalDate.getDate();

  const takeDate = day + " " + mois() + " " + year;

  function mois() {
    switch (month) {
      case 0:
        return "Janvier";
      case 1:
        return "Février";
      case 2:
        return "Mars";
      case 3:
        return "Avril";
      case 4:
        return "Mai";
      case 5:
        return "Juin";
      case 6:
        return "Juillet";
      case 7:
        return "Août";
      case 8:
        return "Septembre";
      case 9:
        return "Octobre";
      case 10:
        return "Novembre";
      case 11:
        return "Décembre";
      default:
    }
  }

  const reservation = () => {
    if (
      (heure.heure && checkbox.checkbox && forfait.forfait && person.person) ===
      undefined
    ) {
      setRequireNotification("* Un ou plusieurs champs n'est pas valide");
    } else {
      let nom = localStorage.getItem("token");
      const saveData = {
        date: takeDate,
        heure: heure.heure,
        checkbox: checkbox.checkbox,
        forfait: forfait.forfait,
        person: person.person,
      };

      if (!nom) {
        localStorage.removeItem("token");
        alert("Veuillez-vous connecter");
      } else {
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          body: JSON.stringify(saveData),
        };
        fetch("https://therott-node.herokuapp.com/client/reservation", options)
          .then((response) => {
            return response.json();
          })
          .then(
            (data) => {
              sendMail();
              setTimeout(function () {
                history.push("/location");
              }, 5000);
            },
            (error) => {
              console.log(error);
            }
          );
      }
    }
  };

  function getClient() {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("token"),
      },
    };

    fetch("https://therott-node.herokuapp.com/client/client-data", options)
      .then((response) => {
        return response.json();
      })
      .then(
        (data) => {
          setClient(data);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  const sendMail = () => {
    const mailData = {
      date: takeDate,
      heure: heure,
      checkbox: checkbox,
      forfait: forfait,
      client: client,
      person: person,
      server: "location@ruffa-karting.fr",
    };
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify(mailData),
    };
    fetch("https://therott-node.herokuapp.com/client/sendmail", options)
      .then((response) => {
        return response.json();
      })
      .then(
        (data) => {
          setResponseMail(data.message);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  return (
    <section>
      <Header />
      <h1 className="reservation-title">Réservation</h1>
      <div className="reservation-content">
        <div className="funkart-text">
          <p>
            <RiErrorWarningLine /> Avant de réserver merci de vérifier la{" "}
            <a
              href="https://www.fun-karting.com/calendrier"
              alt="site-funkart"
              target="_blank"
              rel="noopener noreferrer"
            >
              disponibilité de la piste.{" "}
            </a>
          </p>
          <p>
            FUN KART ayant des groupes il se peut que la piste ne soit pas
            totalement disponible.
          </p>
        </div>

        <div className="reservation-contenair ">
          <div className="calendrier">
            <div>
              <p>
                Quel jour souhaitez-vous venir ?
                <span className="mandatory"> *</span>{" "}
              </p>
            </div>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              excludeDates={[new Date(), subDays(new Date(), 2)]}
              locale="fr"
              dateFormat="dd MMMM yyyy "
              filterDate={isWeekday}
            />
          </div>
          <div className="reservation-form">
            <Form.Group controlId="selectHour">
              <Form.Label>
                À quelle heure ?<span className="mandatory"> *</span>
              </Form.Label>
              <Form.Control
                as="select"
                onChange={handleHeure}
                name="heure"
                required
              >
                <option value="">Choisir une heure</option>
                <option value="9h">9 h</option>
                <option value="10h">10 h</option>
                <option value="11h">11 h</option>
                <option value="12h">12 h</option>
                <option value="13h">13 h</option>
                <option value="14h">14 h</option>
                <option value="15h">15 h</option>
                <option value="16h">16 h</option>
                <option value="17h">17 h</option>
              </Form.Control>
            </Form.Group>
          </div>
          <div className="reservation-form">
            <Form.Group controlId="selectPerson">
              <Form.Label>
                Nombre de personnes ?<span className="mandatory"> *</span>
              </Form.Label>
              <Form.Control
                as="select"
                onChange={handlePerson}
                name="person"
                required
              >
                <option value="">Combien de personne(s)</option>
                <option value="1">1 personne</option>
                <option value="2">2 personnes</option>
              </Form.Control>
            </Form.Group>
          </div>
          <div className="reservation-form">
            <Form.Group controlId="selectForfait">
              <Form.Label>
                Quel forfait ?<span className="mandatory"> *</span>
              </Form.Label>
              <Form.Control
                as="select"
                onChange={handleForfait}
                name="forfait"
                required
              >
                <option value="">Choisir le temps de la location</option>
                <option value="15min">15 minutes / kart</option>
                <option value="30min">30 minutes / kart</option>
              </Form.Control>
            </Form.Group>
          </div>
          <div className="reservation-checkbox">
            <p>
              Licence ?<span className="mandatory"> *</span>
            </p>
            <input
              type="radio"
              name="checkbox"
              id="choix1"
              onChange={handleCheckbox}
              value="je possède la licence annuelle"
            />
            <label htmlFor="choix1">je possède la licence annuelle</label>
            <br />
            <input
              type="radio"
              name="checkbox"
              id="choix2"
              onChange={handleCheckbox}
              value="Je ferais la licence journalière avant de rouler"
            />
            <label htmlFor="choix2">
              Je ferais la licence journalière avant de rouler
            </label>
          </div>
          <div className="button-reservation">
            <span className="required-error">{requireNotification}</span>
            <div className="response">{responseMail}</div>
            <Button type="submit" onClick={reservation}>
              Réserver
            </Button>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
}

export default Reservation;
